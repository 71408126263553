<template>
    <div class="col card bs-5 m-3">
         <h4 class="text-left">Training Schedule Calendar</h4>
         <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
         <p v-if="!details.success">Dashboard currently unavailable.</p>
         <modal no-close-on-backdrop title="Schedule Details" ref="eventModal" width="45r" header-color="primary">
             <div v-if="selectedEvents.length === 0">
                  No events available for the selected date.
                </div>
                <div v-else>
                  <div v-for="event in selectedEvents" :key="event.title">
                      <table>
                          <tr><b>
                          <td>Title :</td>
                          <td>{{ event.title }}</td>
                          </b></tr>
                          <tr><b>
                          <td>Time :</td>
                          <td>{{ event.title2 }}</td>
                          </b></tr>
                      </table>

                  </div>
                </div>
         </modal>
    </div>
</template>
<script>

// import '@fullcalendar/core/vdom';
// import { Calendar } from '@fullcalendar/core';
import urls from '../../../../../data/urls';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/vue';
import listPlugin from '@fullcalendar/list';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name       : 'ScheduleCalendar',
    components : { FullCalendar },
    data () {
        const that = this;
        return {
            date            : null,
            details         : '',
            loading         : false,
            saving          : false,
            calendarOptions : {
                plugins     : [interactionPlugin, dayGridPlugin, listPlugin],
                initialView : 'dayGridMonth',
                selectable  : true,
                eventClick  : function (info) {
                    that.handleEventClick(info);
                },
                dateClick : function (info) {
                    that.handleDateClick(info);
                },
                headerToolbar : {
                    left   : 'prev,next today',
                    center : 'title',
                    right  : 'dayGridMonth,listMonth'
                },
                eventTimeFormat : {
                    hour   : 'numeric',
                    minute : '2-digit'
                    // meridiem : false // Remove this line if you want to display AM/PM
                },
                events           : [],
                eventColor       : '#4169E1',
                eventTextColor   : '#ffffff',
                eventBorderColor : '#ffffff',
                eventDidMount    : function (info) {
                    const event = info.event;
                    console.log('event ' + event.extendedProps.enabled);
                    if (!event.extendedProps.enabled) {
                        event.setProp('backgroundColor', '#989594');
                        // event.setProp('borderColor', '#ffffff');
                    }
                }
            },
            selectedEvents : [],
            selectedDate   : ''

        };
    },
    mounted () {
        this.loadDetails();
    },
    computed : {
        ...mapGetters(['currentUser'])
    },
    methods : {
        loadDetails () {
            console.log('start..............');
            const that = this;
            that.loading = true;
            axios.get(urls.admin.event.training.schedule.calendar).then(function (response) {
                const json = response.data;
                console.log(json.data);
                // console.log(json.error);
                if (json.error === false) {
                    that.details = json;
                    const formattedEvents = [];
                    json.data.forEach(event => {
                        const startDate = new Date(event.start);
                        const endDate = new Date(event.end);
                        const currentDate = new Date(startDate);
                        while (currentDate.getTime() <= endDate.getTime()) {
                            const formattedDate = currentDate.toISOString().split('T')[0];
                            // const startDate = new Date(currentDate + 'T' + event.startTime);
                            // const endDate = new Date(currentDate + 'T' + event.endTime);

                            formattedEvents.push({
                                title   : event.title,
                                start   : formattedDate,
                                // start  : startDate,
                                // end    : endDate,
                                title2  : event.title2,
                                enabled : event.disable

                            });

                            currentDate.setDate(currentDate.getDate() + 1);
                        }
                    });
                    that.calendarOptions.events = formattedEvents;

                    // that.calendarOptions.events = json.data.map(event => ({
                    //     title : event.title,
                    //     start : event.start,
                    //     end   : event.end
                    // }));
                    that.loading = false;
                    // console.log(that.calendarOptions.events);
                }
            });
        },
        handleDateClick (info) {
            this.selectedEvents = [];
            const clickedDate = info.dateStr;
            const events = this.calendarOptions.events;
            const selectedEvents = events.filter(event => event.start === clickedDate);
            if (selectedEvents.length > 0) {
                this.selectedEvents = selectedEvents;
                this.selectedDate = clickedDate;
                this.$refs.eventModal.show();
            }
        },
        handleEventClick (info) {
            this.selectedEvents = [];
            const clickedDate = info.dateStr;
            const events = this.calendarOptions.events;
            const selectedEvents = events.filter(event => event.start === clickedDate);
            if (selectedEvents.length > 0) {
                this.selectedEvents = selectedEvents;
                this.selectedDate = clickedDate;
                this.$refs.eventModal.show();
            }
        }
    }
};
</script>
